import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import {
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import { 
  exitOutline, 
  businessOutline,
  peopleOutline,
  storefrontOutline,
  constructOutline,
  pricetagOutline,
  documentTextOutline
} from 'ionicons/icons';
import { useHistory } from 'react-router-dom';

const Label = styled(IonLabel)`
  margin-left: 10px;
`;

const Menu: React.FC = () => {
  const menuRef = useRef<HTMLIonMenuElement>(null);
  
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(() => {
      menuRef.current?.close();
    });

    return unlisten;
  }, []);
  
  return (
    <IonMenu
      side="start"
      contentId="main"
      data-testid="main-menu"
      ref={menuRef}
    >
      <IonHeader>
        <IonToolbar>
          <IonTitle>Main Menu</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonItem button routerLink={'/businesses'}>
            <IonIcon icon={businessOutline} />
            <Label>Businesses</Label>
          </IonItem>
          <IonItem button routerLink={'/business-entities'}>
            <IonIcon icon={storefrontOutline} />
            <Label>Business Entities</Label>
          </IonItem>
          <IonItem button routerLink={'/consumers'}>
            <IonIcon icon={peopleOutline} />
            <Label>Consumers</Label>
          </IonItem>
          <IonItem button routerLink={'/campaign-manager'}>
            <IonIcon icon={constructOutline} />
            <Label>Campaign Manager</Label>
          </IonItem>
          <IonItem button routerLink={'/businesses/promo-codes'}>
            <IonIcon icon={pricetagOutline} />
            <Label>Promo Code Manager</Label>
          </IonItem>
          <IonItem button routerLink={'/descriptor-review'}>
            <IonIcon icon={documentTextOutline} />
            <Label>Descriptor Review</Label>
          </IonItem>
          <IonItem button href={'/logout'}>
            <IonIcon icon={exitOutline} />
            <Label>Logout</Label>
          </IonItem>
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
