import React from 'react';
import { IonRouterOutlet } from '@ionic/react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { IonReactRouter } from '@ionic/react-router';
import PrivateRoute from '../components/PrivateRoute';
import PublicRoute from '../components/PublicRoute';
import Login from '../pages/auth/Login';
import Logout from '../pages/auth/Logout';
import MerchantSuggestions from '../pages/merchant-suggestions/MerchantSuggestions';
import GroupedMerchantSuggestions from '../pages/merchant-suggestions/GroupedMerchantSuggestions';
import Businesses from '../pages/businesses/Businesses';
import BusinessDetails from '../pages/businesses/BusinessDetails';
import Consumers from '../pages/consumers/Consumers';
import ConsumerTransactions from '../pages/consumers/Consumers/ConsumerTransactions'
import ConsumerDetails from '../pages/consumers/Consumers/ConsumerDetails'
import StoreLocalization from '../pages/stores/StoreLocalization';
import BusinessEntities from '../pages/business-entities/BusinessEntities';
import BusinessEntityDetails from '../pages/business-entities/BusinessEntityDetails';
import BusinessEntityBusinessDetails from '../pages/business-entities/BusinessDetails';
import MergeBusinesses from '../pages/business-entities/MergeBusinesses';
import Menu from '../components/Menu';
import CampaignManagerHome from '../pages/campaigns/CampaignManagerHome';
import CampaignBulkDeactivation from '../pages/campaigns/CampaignBulkDeactivation';
import PromoCodeManagerHome from '../pages/business-promo-codes/PromoCodeManagerHome';
import PromoCodeDetails from '../pages/business-promo-codes/PromoCodeDetails';
import DescriptorReview from '../pages/descriptors/DescriptorReview';

// TODO: Add a "Not Found" page (404)

const Router: React.FC = () => {
  return (
    <IonReactRouter>
      <Menu />

      <Route path="/logout" component={Logout} exact />
      <IonRouterOutlet id="main">
        <Switch>
          <PrivateRoute
            exact
            path="/merchant-suggestions"
            component={MerchantSuggestions}
          />

          <PrivateRoute
            exact
            path="/merchant-suggestions/:descriptionId"
            component={GroupedMerchantSuggestions}
          />

          <PrivateRoute 
            exact
            path="/businesses/promo-codes"
            component={PromoCodeManagerHome}
          />

          <PrivateRoute 
            exact
            path="/businesses/promo-codes/:id"
            component={PromoCodeDetails}
          />

          <PrivateRoute
            exact
            path="/businesses"
            component={Businesses}
          />

          <PrivateRoute
            exact
            path="/businesses/:id"
            component={BusinessDetails}
          />

          <PrivateRoute
            exact
            path="/business-entities"
            component={BusinessEntities}
          />

          <PrivateRoute
            exact
            path="/business-entities/:id"
            component={BusinessEntityDetails}
          />

          <PrivateRoute
            exact
            path="/business-entities/:id/business"
            component={BusinessEntityBusinessDetails}
          />

          <PrivateRoute
            exact
            path="/business-entities/:id/business/merge"
            component={MergeBusinesses}
          />

          <PrivateRoute
            path="/businesses/:businessId/stores/:storeId/localization"
            component={StoreLocalization}
          />
          
          <PrivateRoute
            exact
            path="/consumers"
            component={Consumers}
          />

          <PublicRoute
            path="/login"
            component={Login}
            exact
            routerDirection="back"
          />

          <PrivateRoute 
            exact
            path="/consumer/:id/transactions" 
            component={ConsumerTransactions}
          />

          <PrivateRoute 
            exact
            path="/consumer/:id/details" 
            component={ConsumerDetails}
          />

          <PrivateRoute 
            exact
            path="/campaign-manager" 
            component={CampaignManagerHome}
          />

          <PrivateRoute 
            exact
            path="/campaign-manager/bulk-deactivation" 
            component={CampaignBulkDeactivation}
          />

          <PrivateRoute 
            exact
            path="/descriptor-review" 
            component={DescriptorReview}
          />
        </Switch>

        <Redirect exact path="/" to="/businesses" />
      </IonRouterOutlet>
    </IonReactRouter>
  );
};

export default Router;
