import { businessPromoCodesActionTypes } from './business-promo-codes-actions';
import { BusinessPromoCode, BusinessPromoCodeWithDetails } from './business-promo-codes-types';
import { PaginatedResponse } from '../../types/api';
import { actionTypes } from '../actions/actions';
import { Action } from '../actions/actions-types';

interface BusinessPromoCodesStore {
  list: PaginatedResponse<BusinessPromoCode[]>;
  searchQuery: string;
  selected: BusinessPromoCodeWithDetails | undefined;
};

export const businessPromoCodesInitialState = (): BusinessPromoCodesStore =>
  ({ 
    list: {
      data: undefined,
      pagination: {
        page: 0,
        last: 0,
        limit: 0,
        total: 0
      }
    } as PaginatedResponse<BusinessPromoCode[]>,
    searchQuery: '',
    selected: undefined,
  });

export const businessPromoCodesReducer = (
  state = businessPromoCodesInitialState(),
  action: Action<BusinessPromoCodesStore>,
): any => {
  switch (action.type) {
    case businessPromoCodesActionTypes.SET_BUSINESS_PROMO_CODES: {
      const { pagination, data } = action.payload.list;

      if (pagination.page === 1) {
        return {
          ...state,
          list: { data, pagination}
        };
      }

      if (pagination.page <= pagination.last) {        
        const merged = (
          data ? 
          state.list.data?.concat(data) || data : 
          state.list.data
        )

        return {
          ...state,
          list: {
            data: merged,
            pagination
          }
        };
      }
      return state;
    }

    case businessPromoCodesActionTypes.SET_SELECTED_BUSINESS_PROMO_CODE: {
      const { selected } = action.payload;

      if (selected) {
        return {
          ...state,
          selected,
        };
      }

      return state;
    }
  
    case businessPromoCodesActionTypes.UPDATE_SELECTED_BUSINESS_PROMO_CODE_INFO: {
      const { updatedBusinessPromoCodeInfo } = action.payload as any;

      const { selected } = state;
      
      if (selected) {
        return {
          ...state,
          selected: {
            ...selected,
            ...updatedBusinessPromoCodeInfo,
          },
        };
      }

      return state;
    }

    case businessPromoCodesActionTypes.SET_BUSINESS_PROMO_CODES_SEARCH_QUERY: {
      const { searchQuery } = action.payload;

      if (searchQuery !== undefined) {
        return {
          ...state,
          searchQuery,
        };
      }

      return state;
    }

    case businessPromoCodesActionTypes.CLEAR_BUSINESS_PROMO_CODES:
    case actionTypes.CLEAR_STATE:
      return {
        ...state,
        list: businessPromoCodesInitialState().list,
      };

    default:
      return state;
  }
};
