import { businessEntitiesActionTypes } from './business-entities-actions';
import { BusinessEntity, BusinessEntityWithDetails, EditableBusinessInfo } from './business-entities-types';
import { PaginatedResponse } from '../../types/api';
import { actionTypes } from '../actions/actions';
import { Action } from '../actions/actions-types';

interface BusinessEntitiesStore {
  list: PaginatedResponse<BusinessEntity[]>;
  searchQuery: string;
  selected: BusinessEntityWithDetails | undefined;
};

export const businessEntitiesInitialState = (): BusinessEntitiesStore =>
  ({ 
    list: {
      data: undefined,
      pagination: {
        page: 0,
        last: 0,
        limit: 0,
        total: 0
      }
    } as PaginatedResponse<BusinessEntity[]>,
    searchQuery: '',
    selected: undefined,
  });

export const businessEntitiesReducer = (
  state = businessEntitiesInitialState(),
  action: Action<BusinessEntitiesStore>,
): any => {
  switch (action.type) {
    case businessEntitiesActionTypes.SET_BUSINESS_ENTITIES: {
      const { pagination, data } = action.payload.list;

      if (pagination.page === 1) {
        return {
          ...state,
          list: { data, pagination}
        };
      }

      if (pagination.page <= pagination.last) {        
        const merged = (
          data ? 
          state.list.data?.concat(data) || data : 
          state.list.data
        )

        return {
          ...state,
          list: {
            data: merged,
            pagination
          }
        };
      }
      return state;
    }

    case businessEntitiesActionTypes.SET_SELECTED_BUSINESS_ENTITY: {
      const { selected } = action.payload;

      if (selected) {
        return {
          ...state,
          selected,
        };
      }

      return state;
    }

    case businessEntitiesActionTypes.SET_BUSINESS_ENTITIES_SEARCH_QUERY: {
      const { searchQuery } = action.payload;

      if (searchQuery !== undefined) {
        return {
          ...state,
          searchQuery,
        };
      }

      return state;
    }
  
    case businessEntitiesActionTypes.UPDATE_SELECTED_ENTITY_BUSINESS_INFO: {
      const { updatedEntityBusinessInfo } = action.payload as any;

      const { selected } = state;
      
      if (selected) {
        return {
          ...state,
          selected: {
            ...selected,
            ...updatedEntityBusinessInfo
          },
        };
      }

      return state;
    }

    case businessEntitiesActionTypes.CLEAR_BUSINESS_ENTITIES:
    case actionTypes.CLEAR_STATE:
      return {
        ...state,
        list: businessEntitiesInitialState().list,
      };

    default:
      return state;
  }
};
