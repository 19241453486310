import React from 'react';
import { Descriptor, DescriptorReviewTypeMap } from '../../../../../features/descriptors/descriptors-types';
import { Container, ButtonsContainer, Button } from './styles';

type DescriptorItemProps = {
  descriptor: Descriptor;
  onAction: (
    id: number,
    action: keyof typeof DescriptorReviewTypeMap
  ) => void;
  selectedAction: keyof typeof DescriptorReviewTypeMap | null;
  isLoading: boolean;
};

const DescriptorItem: React.FC<DescriptorItemProps> = ({
  descriptor,
  onAction,
  selectedAction,
  isLoading
}) => {
  return (
    <Container data-testid="descriptor-item">
      <p data-testid="tx-count-180d">
        <strong>Tx Count (180d):</strong> {descriptor.txCountLast180Days}
      </p>
      <p data-testid="parsed-descriptor">
        <strong>Parsed Descriptor:</strong> {descriptor.parsedDescriptor}
      </p>
      <p data-testid="descriptor-text">
        <strong>MC Descriptor:</strong> {descriptor.descriptorText}
      </p>
      <p data-testid="match-confidence-score">
        <strong>MC Confidence Score:</strong> {descriptor.matchConfidenceScore}
      </p>
      <p data-testid="suggested-merchant-name">
        <strong>Suggested Merchant Name:</strong> {descriptor.cleansedMerchantName || 'N/A'}
      </p>
      <p data-testid="suggested-address">
        <strong>Suggested Address:</strong> {descriptor.cleansedStreetAddr || 'N/A'}
      </p>
      <p data-testid="suggested-city">
        <strong>Suggested City:</strong> {descriptor.cleansedCityName || 'N/A'}
      </p>
      <p data-testid="suggested-state">
        <strong>Suggested State:</strong> {descriptor.cleansedStateProvidenceCode || 'N/A'}
      </p>

      <ButtonsContainer>
        <Button
          color="success"
          fill={selectedAction === 'accept' ? 'solid' : 'outline'}
          onClick={() => onAction(descriptor.id, 'accept')}
          expand="block"
          disabled={isLoading}
        >
          Accept
        </Button>
        <Button
          color="danger"
          fill={selectedAction === 'reject' ? 'solid' : 'outline'}
          onClick={() => onAction(descriptor.id, 'reject')}
          expand="block"
          disabled={isLoading}
        >
          Reject
        </Button>
        <Button
          color="medium"
          fill={selectedAction === 'skip' ? 'solid' : 'outline'}
          onClick={() => onAction(descriptor.id, 'skip')}
          expand="block"
          disabled={isLoading}
        >
          Skip
        </Button>
      </ButtonsContainer>
    </Container>
  );
};

export default DescriptorItem;
