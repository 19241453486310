import React from 'react';
import { IonInput } from '@ionic/react';
import { Controller, Control } from 'react-hook-form';

type InputProps = {
  name: string;
  type:
    | 'number'
    | 'time'
    | 'text'
    | 'date'
    | 'email'
    | 'password'
    | 'search'
    | 'tel'
    | 'url'
    | undefined;
  control: Control;
  rules?: any;
  placeholder?: string;
  onChange?: any;
  onBlur?: any;
  inputClass?: string;
  autocapitalize?: string;
  value?: any;
};

const TextFieldInput: React.FC<InputProps> = ({
  name,
  type,
  control,
  rules,
  placeholder,
  onChange: onChangeComponentProp,
  onBlur: onBlurComponentProp,
  inputClass,
  autocapitalize,
  value
}) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={value}
      render={({ field: { onChange, onBlur, value } }) => {
        return (
          <IonInput
            autocapitalize={autocapitalize}
            class={inputClass || 'login-default-input'}
            type={type}
            value={value}
            onIonChange={(e) => {
              if (onChangeComponentProp) onChangeComponentProp(e);
              onChange(e.detail.value);
            }}
            onIonBlur={() => {
              if (onBlurComponentProp) onBlurComponentProp();
              onBlur();
            }}
            placeholder={placeholder}
          />
        );
      }}
    />
  );
};

export default TextFieldInput;
