import React, { useState, useEffect } from 'react';
import { IonPage, IonContent, IonHeader, IonToolbar, IonTitle, IonButton, IonList, IonSpinner, IonAlert, IonButtons, IonMenuButton, useIonToast } from '@ionic/react';
import { useGetDescriptors, usePostDescriptorsReview } from '../../../features/descriptors/descriptors-resolver';
import { Centered } from './styles';
import DescriptorItem from './components/DescriptorItem';
import { descriptorReviewDataLimit } from '../../../shared/constants';
import { DescriptorReviewTypeMap } from '../../../features/descriptors/descriptors-types';

const getParams = {
  limit: descriptorReviewDataLimit
};

const DescriptorReview: React.FC = () => {
  const [presentToast, dismissToast] = useIonToast();

  const [
    {
      data: descriptors,
      isLoading: areDescriptorsLoading,
      error: descriptorsError
    },
    getDescriptors
  ] = useGetDescriptors();

  const [
    { isLoading: isPostReviewLoading },
    postReview,
  ] = usePostDescriptorsReview();

  const [descriptorReviews, setDescriptorReviews] = useState<
    Record<number, keyof typeof DescriptorReviewTypeMap>
  >({});

  const handleAction = (id: number, action: keyof typeof DescriptorReviewTypeMap) => {
    setDescriptorReviews(prev => ({ ...prev, [id]: action }));
  };

  const allActionsSelected = descriptors && descriptors.every(d => descriptorReviews[d.id]);

  const handleSubmit = async () => {
    if (allActionsSelected) {
      try {
        const payload = {
          descriptors: Object
            .entries(descriptorReviews)
            .map(([id, reviewResult]) => ({
              id: parseInt(id),
              reviewType: DescriptorReviewTypeMap[reviewResult]
            }))
        };
        const result = await postReview(payload);

        if (result.errorCode) {
          if (result.statusCode && result.statusCode !== 500) {
            throw new Error('Review period expired, please refresh the page to get new descriptors.');
          } else {
            throw new Error('Could not submit review. Try again later.');
          }
        }
        
        presentToast({
          message: 'Review submitted successfully',
          duration: 1500,
          position: 'top',
          color: 'success'
        });
        getDescriptors({
          refresh: true,
          params: getParams,
        });
      } catch (error: any) {
        presentToast({
          message: error.message,
          position: 'top',
          color: 'danger',
          buttons: [{
            side: 'end',
            text: 'OK',
            handler: () => {
              dismissToast();
            },
          }]
        });
      }
    }
  };

  useEffect(() => {
    getDescriptors({
      refresh: true,
      params: getParams,
    });
  }, []);

  useEffect(() => {
    setDescriptorReviews({});
  }, [descriptors]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton data-testid="menu-button"></IonMenuButton>
          </IonButtons>
          <IonTitle>Descriptor Review</IonTitle>
        </IonToolbar>
      </IonHeader>

      {areDescriptorsLoading && (
        <Centered>
          <IonSpinner data-testid="page-spinner" title="page-spinner" name="crescent" />
        </Centered>
      )}
      
      {!areDescriptorsLoading && (
        <IonContent>
          {descriptors && descriptors.length > 0
            ? (
              <>
                <IonList>
                  {descriptors.map(descriptor => (
                    <DescriptorItem
                      key={descriptor.id}
                      descriptor={descriptor}
                      onAction={handleAction}
                      selectedAction={descriptorReviews[descriptor.id] || null}
                      isLoading={isPostReviewLoading || areDescriptorsLoading}
                    />
                  ))}
                </IonList>
                <IonButton
                  expand="block"
                  disabled={
                    !allActionsSelected ||
                    isPostReviewLoading ||
                    areDescriptorsLoading
                  }
                  onClick={handleSubmit}
                  style={{ margin: '16px 5px' }}
                >
                  Submit
                </IonButton>  
              </>
            )
            : <Centered>No descriptors to review</Centered>
          }
        </IonContent>
      )}

      <IonAlert
        isOpen={!!descriptorsError}
        data-testid="req-error-message"
        cssClass="modal-dark-mode"
        header="Network Error"
        message="There was an error processing your request. Please try again later."
        buttons={['OK']}
      />
    </IonPage>
  );
};

export default DescriptorReview;