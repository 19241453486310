import styled from 'styled-components';
import { IonButton } from '@ionic/react';

export const Container = styled.div`
  padding: 30px;
  border-bottom: 1px solid #e0e0e0;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 600px;
`;

export const Button = styled(IonButton)`
  flex: 1;
  margin: 0;

  ::part(native) {
    border-radius: 0;
  }
`;